import { Component, OnInit } from '@angular/core';
import { PayPalService } from 'src/app/services/pay-pal.service';
import { environment } from 'src/environments/environment';
// import { loadScript } from '@paypal/paypal-js';

@Component({
  selector: 'app-pay-pal-test',
  templateUrl: './pay-pal-test.component.html',
  styleUrls: ['./pay-pal-test.component.scss']
})
export class PayPalTestComponent implements OnInit {

  paypal;

  constructor(private paypalService: PayPalService) { }

  ngOnInit(): void {
    // hello
    // loadScript(
    //   {
    //   "client-id": "AQ8I2vtTtLSTugxIs-WjkMJzN0FI63WmCpWay9UAdmHMCcRCNw1njxzLI2fVwzltpkfR_xL_oYvAO7mW",
    //   "currency": "SEK",
      
    // })
    //   .then((paypal) => {
    //     paypal
    //       .Buttons({
    //         createSubscription: (data, actions) => {
    //           // console.log("Hello", this.selectedSub);
    //           return actions.subscription.create({
    //             plan_id: "plan_id"
    //           });
    //         },
    //         // advanced: {
    //         //   commit: 'true',
    //         // },
    //         style: {
    //           label: 'subscribe',
    //           layout: 'vertical',
    //           color: 'gold',
    //           shape: 'rect',
    //           height: 40, // 20-55
    //         },
    //         // fundingSource: 'PAYPAL',
    //       })
    //       .render("#paypal-button-container")
    //       .catch((error) => {
    //         console.error("failed to render the PayPal Buttons", error);
    //       });
    //   })
    //   .catch((error) => {
    //     console.error("failed to load the PayPal JS SDK script", error);
    //   });
  }


  // private plans = [];
  // public configs = {};

  // @ViewChild("100SEK") basicSubscription?: NgxPaypalComponent;
  // @ViewChild("200SEK") advancedSubscription?: NgxPaypalComponent;

  // constructor(private payPalScriptService: PayPalScriptService) {
  //   this.plans = plans;
  // }

  // ngOnInit() {
  //   this.plans.map((plan) => {
  //     this.configs[plan.name] = this.getConfig(plan.id);
  //   });
  //   this.payPalScriptService.registerPayPalScript(
  //     {
  //       clientId: environment.paypalKey,
  //       currency: "SEK",
  //       vault: "true",
  //     },
  //     (payPalApi) => {
  //       if (this.basicSubscription) {
  //         this.basicSubscription.customInit(payPalApi);
  //       }
  //       if (this.advancedSubscription) {
  //         this.advancedSubscription.customInit(payPalApi);
  //       }
  //     }
  //   );
  // }

  // getConfig(plan_id: string): IPayPalConfig {
  //   return {
  //     clientId: 'AQ8I2vtTtLSTugxIs-WjkMJzN0FI63WmCpWay9UAdmHMCcRCNw1njxzLI2fVwzltpkfR_xL_oYvAO7mW',
  //     currency: "SEK",
  //     vault: "true",
  //     // advanced: {
  //     //   commit: "true",
  //     // },
  //     style: {
  //       label: 'subscribe',
  //       layout: 'vertical',
  //       color: 'gold',
  //       shape: 'rect',
  //       height: 40, // 20-55
  //       tagline: false,
  //     },
  //     // createSubscription: (actions) => {
  //     //   console.log("CREATE_SUB");
  //     //   return actions.subscription.create({
  //     //     plan_id,
  //     //     custom_id: "",
  //     //   });
  //     // },
  //     fundingSource: 'PAYPAL',
  //     onApprove: function (data: IOnApproveCallbackData, actions: any) {
  //       console.log("subscription data:", data);
  //       actions.subscription.get().then((details) => {
  //         console.log("subscription details:", details);
  //         alert("Success to subscribe!");
  //       });
  //     },
  //     onCancel: (data, actions) => {
  //       console.log("OnCancel", data, actions);
  //     },
  //     onError: (err) => {
  //       console.log("OnError", err);
  //     },
  //     onClick: (data, actions) => {
  //       console.log("Clicked:", data, actions);
  //     },
  //   };
  // }
}
