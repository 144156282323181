import { Injectable, TemplateRef } from '@angular/core';
import { NgbOffcanvas, OffcanvasDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HelperService {

  give: Give = "once";
  isSideMenuOpen: boolean = false;
  offcanvasContent?: TemplateRef<any>;
  closeResult = "";
  selectedEvent = new BehaviorSubject<ComingEvents>(undefined);
  activeTab = new BehaviorSubject<ActiveTab>(1);
  selectedSession = new BehaviorSubject<Sessions>(undefined);

  constructor(private offcanvasService: NgbOffcanvas) { }

  selectSession(session: Sessions) {
    this.selectedSession.next(session)
  }

  setActiveTab(active: ActiveTab) {
    this.activeTab.next(active);
  }

  selectEvent(event: ComingEvents) {
    this.selectedEvent.next(event);
  }

  get getIsSideMenuOpen() {
    return this.isSideMenuOpen;
  }

  toggleIsSideMenuOpen(content?: TemplateRef<any>) {
    if (this.isSideMenuOpen === true) {
      this.offcanvasService.dismiss(content);
      this.isSideMenuOpen = false;
    }
    else if (this.isSideMenuOpen === false) {
      this.offcanvasService.open(content, { position: 'end' }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
      this.isSideMenuOpen = true;
    }
  }

  private getDismissReason(reason: any): void {
    if (reason === OffcanvasDismissReasons.ESC) {
      // console.log('by pressing ESC');
    } else if (reason === OffcanvasDismissReasons.BACKDROP_CLICK) {
      // console.log('by clicking on the backdrop');
      this.isSideMenuOpen = false;
    } else {
      // console.log(`with: ${reason}`);
    }
  }

  closeSideMenu(content?: TemplateRef<any>) {
    this.offcanvasService.dismiss(content);
    this.isSideMenuOpen = false;
  }

  get getGive() {
    return this.give;
  }
  setIsGive(give: Give) {
    this.give = give;
  }
}
