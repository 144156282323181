import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }
  
  getUser() {
    return this.http.get<any>(this.baseUrl + 'api/1.0/user');
  }
  
  getCurrentUser() {
    return this.http.get<any>(this.baseUrl + 'api/1.0/user/current');
  }

  //getUserByEmail(email) {
  //  return this.http.get<any>(this.baseUrl + 'api/1.0/user/email/' + email);
  //}

  updateUser(user: any) {
    return this.http.put<any>(this.baseUrl + 'api/1.0/user', user);
  }

  findUsers(searchText = '') {
    let url = this.baseUrl + 'api/1.0/user?q=' + searchText;
    
    return this.http.get<any>(url);
  }

  getUserById(userId: string) {
    return this.http.get<any>(this.baseUrl + 'api/1.0/user/' + userId);
  }

  removeUser(userId: string) {
    return this.http.delete(this.baseUrl + 'api/1.0/user/' + userId);
  }
  
}
