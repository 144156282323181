import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: [
    '../../../../styles.css',
    './button.component.scss'
  ]
})
export class ButtonComponent implements OnInit {

  @Input() click;
  @Input() text: string | HTMLElement = "";
  @Input() type?: string;
  @Input() color?: ButtonColor;
  @Input() bordered?: boolean;
  @Input() borderColor?: string;
  @Input() width?: string;
  @Input() height?: string;
  @Input() class?: string = "";
  @Input() disabled: boolean = false;

  @Output() btnClick = new EventEmitter();

  constructor() { }


  ngOnInit(): void {
  }

  onClick() {
    this.btnClick.emit();
  }

}
