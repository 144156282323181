import { Component, OnInit, Output, EventEmitter, HostListener, Input, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { comingEvents } from 'src/app/models/comingEvents';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-nav-menu-top',
  templateUrl: './nav-menu-top.component.html',
  styleUrls: ['./nav-menu-top.component.scss', '../../../../styles.css'],
})
export class NavMenuTopComponent implements OnInit {

  innerWidth: any;
  navBreakpoint: string = "500";
  comingEventsData: ComingEvents[] = comingEvents;

  @Input() offcanvasContent: TemplateRef<any> | undefined = undefined;
  @Output() expanded = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private helpService: HelperService,
  ) {

  }

  ngOnInit(): void {
    // console.log("IN TOP", this.router.routerState.root.url);
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  getIsExpanded() {
    return this.helpService.getIsSideMenuOpen;
  }

  closeSideMenu() {
    // shouldReuseRoute makes the page re-render when page is already active
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    return this.helpService.closeSideMenu(this.offcanvasContent);
  }

  toggleSideMenu() {
    this.helpService.toggleIsSideMenuOpen(this.offcanvasContent);
  }

  get getRouteName(): string | undefined {
    if (this.router.url === "/") { return ""; }
    if (this.router.url === "/music") { return "MUSIC"; }
    if (this.router.url === "/resources") { return ""; }
    if (this.router.url === "/lyrics") { return "LYRICS"; }
    if (this.router.url === "/sessions") { return "SESSIONS"; }
    if (this.router.url === "/coming-events") { return "EVENTS"; }
    if (this.router.url === "/coming-events/event/HIM-conference") { return "EVENTS"; }
    if (this.router.url === "/about") { return "ABOUT"; }
    if (this.router.url === "/giving") { return "GIVING"; }
  }

  selectEvent(event: ComingEvents) {
    // shouldReuseRoute makes the page re-render when page is already active
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    this.helpService.selectEvent(event);
    this.closeSideMenu();
  }

    // Filters out the events that have passed
    filteredEvents() {
      let now = moment().subtract(1, "d").format("YYYYMMDD");
      return this.comingEventsData.filter((event) => moment(event.startDate).format("YYYYMMDD") > now).slice(0, 4);
    }
}
