
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LyricsService } from 'src/app/services/lyrics.service';
import { AddLyricsModalComponent } from './add-lyrics/add-lyrics-modal.component';
import { DeleteLyricsModalComponent } from './delete-lyrics-modal/delete-lyrics-modal.component';
import { EditLyricsModalComponent } from './edit-lyrics/edit-lyrics-modal.component';

@Component({
  templateUrl: './lyrics-modal.component.html',
  styleUrls: ['./lyrics-modal.component.scss', '../../../../../styles.css'],
})
export class LyricsModalComponent implements OnInit {

  searchValue: string = "";
  lyrics: Lyrics[] = [];
  selectedLyricsId: string = "";

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private lyricsService: LyricsService,
  ) {
    this.lyricsService.editStatus.subscribe(data => {
      // console.log("data:", data);
      this.updateList();
    })
  }

  ngOnInit(): void {
    this.lyricsService.getAllLyrics().subscribe((lyrics) => (this.lyrics = lyrics));
    this.lyricsService.selectedLyricsId.subscribe(e => this.selectedLyricsId = e);
  }

  openAdd() {
    this.modalService.open(AddLyricsModalComponent, {
      size: 'lg'
    });
  }

  openEdit(lyricId: string) {
    this.selectLyricsId(lyricId);
    this.modalService.open(EditLyricsModalComponent, { size: 'lg' });
  }

  openDelete(lyricId: string) {
    this.selectLyricsId(lyricId);
    this.modalService.open(DeleteLyricsModalComponent, { size: 'md' })
  }

  selectLyricsId(lyricsId: string) {
    this.lyricsService.changeLyricsId(lyricsId);
  }

  public updateList() {
    // console.log("click");
    this.lyricsService.getAllLyrics().subscribe((lyrics) => (this.lyrics = lyrics));
  }

  filterList(): Lyrics[] {
    if (this.lyrics) {

      const filtered = this.lyrics.filter(e => {
        return (
          this.searchValue === "" ||
          e.title.toLowerCase().includes(this.searchValue.toLowerCase())
        );
      });

      return filtered;
    } else {
      return [];
    }
  }
}