import { Component, HostListener, OnInit } from '@angular/core';
import { sessionsData } from 'src/app/models/sessions';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {
  
  innerWidth: number = 0;
  sessions: Sessions[] = sessionsData;

  constructor(private helperService: HelperService) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  sessionArtSize() {
    if (this.innerWidth <= 450) {
      return { width: "280px" };
    }
    if (this.innerWidth > 450) {
      return { width: "334px" };
    }
    // if (this.innerWidth >= 1000) {
    //   return { width: "500px" };
    // }
  }

  headerSize() {
    if (this.innerWidth <= 450) {
      return { width: "280px" };
    }
    if (this.innerWidth > 450) {
      return { width: "334px" };
    }
  }

  setSession(session: Sessions) {
    this.helperService.selectSession(session);
  }
}
