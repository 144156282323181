import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderResponseBody } from '@paypal/paypal-js';

@Component({
  selector: 'app-paypal-modal',
  templateUrl: './paypal-modal.component.html',
  styleUrls: ['./paypal-modal.component.scss']
})
export class PaypalModalComponent implements OnInit, AfterViewInit, OnDestroy {

  amount: string = '0';
  currency: string = '';

  @Input() orderDetailsSub?: any;
  @Input() orderDetailsSingle?: OrderResponseBody;
  @Input() subType: PaypalSubState;
  @Input() errorMessage?: string;
  @Input() cancelMessage?: string;

  constructor(public activeModal: NgbActiveModal, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => { return false; };
  }

  ngOnInit(): void {

  }
  
  ngAfterViewInit(): void {

    if (this.subType === "subApproved") {
      console.log("VALUE:", this.orderDetailsSub.billing_info.last_payment.amount.value);
      this.amount = this.orderDetailsSub.billing_info.last_payment.amount.value.split(".")[0];
      this.currency = this.orderDetailsSub.billing_info.last_payment.amount.currency_code;
    }
    if (this.subType === "singleApproved") {
      this.amount = this.orderDetailsSingle.purchase_units[0].amount.value;
      this.currency = this.orderDetailsSingle.purchase_units[0].amount.currency_code;
    }
  }

  ngOnDestroy(): void {
    this.router.navigateByUrl("/giving");
  }

  public OnClose() {
    this.activeModal.dismiss('Cross click');
    this.router.navigateByUrl("/giving");
  }

  // open() {
  //   this.modalService.open(PaypalModalComponent, { ariaLabelledBy: 'modal-basic-title' }).result.then(
  //     (result) => {
  //       this.closeResult = `Closed with: ${result}`;
  //     },
  //     (reason) => {
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //     },
  //   );
  // }

  // private getDismissReason(reason: any): string {
	// 	if (reason === ModalDismissReasons.ESC) {
	// 		return 'by pressing ESC';
	// 	} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
	// 		return 'by clicking on a backdrop';
	// 	} else {
	// 		return `with: ${reason}`;
	// 	}
	// }
}
