import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appEllipsis]'
})
export class EllipsisDirective {
  element: ElementRef;

  @Input() ellipsisData: any;
  constructor(el: ElementRef) {
    this.element = el;
  }

  ngOnInit() {

  }

  ngAfterContentInit() {

  }
  ngAfterViewInit() {
    // console.log(this.element.nativeElement);
    this.element.nativeElement.attributes.lastWindowResizeTime = 0;
    this.element.nativeElement.attributes.lastWindowResizeWidth = 0;
    this.element.nativeElement.attributes.lastWindowResizeHeight = 0;
    this.element.nativeElement.attributes.lastWindowTimeoutEvent = null;
    /* State Variables */
    this.element.nativeElement.attributes.isTruncated = false;

    this.buildEllipsis();
  }
  buildEllipsis() {
    if (typeof (this.ellipsisData) !== 'undefined') {
      var bindArray = this.ellipsisData.split(" ");
      var i = 0;
      var ellipsisSymbol = '&hellip;';
      var appendString = ellipsisSymbol;

      // console.log(appendString);

      this.element.nativeElement.attributes.isTruncated = false;
      this.element.nativeElement.innerHTML = this.ellipsisData;

      // If text has overflow
      if (this.isOverflowed(this.element.nativeElement)) {
        var bindArrayStartingLength = bindArray.length,
          initialMaxHeight = this.element.nativeElement.clientHeight;

        this.element.nativeElement.innerHTML = this.ellipsisData + appendString;

        // Set complete text and remove one word at a time, until there is no overflow
        for (; i < bindArrayStartingLength; i++) {
          bindArray.pop();
          this.element.nativeElement.innerHTML = bindArray.join(" ") + appendString;

          if (this.element.nativeElement.scrollHeight < initialMaxHeight || this.isOverflowed(this.element.nativeElement) === false) {
            this.element.nativeElement.attributes.isTruncated = true;
            break;
          }
        }
      }
    }
  }

  isOverflowed(el: any) {
    return el.scrollHeight > el.clientHeight;
  }
}