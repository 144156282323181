import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { musicData } from 'src/app/models/music';
import { MusicService } from 'src/app/services/music.service';

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: [
    '../../../../styles.css',
    './music.component.scss'
  ]
})
export class MusicComponent implements OnInit, AfterViewInit {

  innerWidth: any;
  albumId: AlbumId = "";
  musicProvider: MusicProvider = "";
  isResource: boolean = false;

  albumData: MusicData[] = musicData;

  @ViewChild('album') albumBody: ElementRef;
  albumWidth: number;

  constructor(private musicService: MusicService, private router: Router) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.musicService.selectedAlbumId.subscribe((id) => this.albumId = id);
    if (this.router.url !== "/music") {
      this.closeCard();
    }
  }
  
  ngAfterViewInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.router.url !== "/music") {
      this.closeCard();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.albumWidth = this.albumBody.nativeElement.offsetWidth;
    this.innerWidth = window.innerWidth;
  }

  setAlbumId(newAlbumId: AlbumId) {
    this.isResource = false;
    this.musicProvider = "";
    this.albumId = newAlbumId;
    this.albumWidth = this.albumBody.nativeElement.offsetWidth;
  }

  setMusicProvider(provider: MusicProvider) {
    this.isResource = false;
    this.musicProvider = provider;
  }

  setResources(value: boolean) {
    this.isResource = value;
    // console.log(this.isResource);
  }

  closeCard() {
    this.albumId = "";
    this.musicProvider = "";
    this.isResource = false;
  }

  getContainerSize() {
    if (this.innerWidth < 500) { return 300; }
    else { return 500; }
  }

  renderType(musicType: AlbumType) {
    if (musicType === "album") {
      return "ALBUM";
    } else if (musicType === "ep") {
      return "EP";
    } else if (musicType === "single") {
      return "SINGLE";
    } else {
      return "";
    }
  }

  showFirstPageWithResources(selectedAlbumId: string): boolean {
    if (this.albumId === selectedAlbumId && this.musicProvider === '' && this.isResource === false) {
      return true;
    } else {
      return false;
    }
  }

  showFirstPageNoResources(selectedAlbumId: string): boolean {
    if (this.musicProvider !== '' && this.albumId !== '' && this.albumId === selectedAlbumId && this.isResource === false) {
      return true;
    } else {
      return false;
    }
  }

  showSpotify(selectedAlbumId: string): boolean {
    if (this.musicProvider === 'spotify' && this.albumId === selectedAlbumId && this.isResource === false) {
      return true;
    } else {
      return false;
    }
  }
  
  showAppleMusic(selectedAlbumId: string): boolean {
    if (this.musicProvider === 'apple' && this.albumId === selectedAlbumId && this.isResource === false) {
      return true;
    } else {
      return false;
    }
  }

  showResources(selectedAlbumId: string): boolean {
    if (this.isResource === true && this.albumId === selectedAlbumId) {
      return true;
    } else {
      return false;
    }
  }

  lyricsRowHeight(pdfSrc?: LyricsPdfSrc) {
    const notUndefinedOrEmpty = pdfSrc.lyrics !== "" && pdfSrc.lyrics !== undefined;
    if (pdfSrc === undefined || (pdfSrc.lyrics === "" && pdfSrc.lyricsChords === "")) { return "0%"; }
    else if (notUndefinedOrEmpty && pdfSrc.lyricsChords !== "") { return "50%"; }
    else if (notUndefinedOrEmpty && pdfSrc.lyricsChords === "") { return "100%"; }
    else { return "0%"; }
  }

  chordsRowHeight(pdfSrc?: LyricsPdfSrc) {
    const notUndefinedOrEmpty = pdfSrc.lyricsChords !== "" && pdfSrc.lyricsChords !== undefined;
    if (pdfSrc === undefined || (pdfSrc.lyricsChords === "" && pdfSrc.lyrics === "")) { return "0%"; }
    else if (notUndefinedOrEmpty && pdfSrc.lyrics !== "") { return "50%"; }
    else if (notUndefinedOrEmpty && pdfSrc.lyrics === "") { return "100%"; }
    else { return "0%"; }
  }
}
