const nvgTitle = "Noise Village Gathering";
// const nvgText = "Noise Village Gathering is our weekly worship service. It’s a place where believers from different local communities and denominations gather for the single purpose of worshiping Jesus. We gather every Thursday 6 pm in Noise Village Upper Room.";
const nvgText = "";
const nvgStartTime = "18:00";
const nvgType = "nvg";

export const comingEvents: ComingEvents[] = [
  // {
  //   eventId: "nvg_060423_1800",
  //   title: nvgTitle,
  //   startDate: "6/4 - 2023",
  //   startTime: nvgStartTime,
  //   mainText: nvgText,
  //   moreInfo: false,
  //   type: nvgType,
  // },
  // {
  //   eventId: "nvg_130423_1800",
  //   title: nvgTitle,
  //   startDate: "13/4 - 2023",
  //   startTime: nvgStartTime,
  //   mainText: nvgText,
  //   moreInfo: false,
  //   type: nvgType,
  // },
  // {
  //   eventId: "nvg_200423_1800",
  //   title: nvgTitle,
  //   startDate: "20/4 - 2023",
  //   startTime: nvgStartTime,
  //   mainText: nvgText,
  //   moreInfo: false,
  //   type: nvgType,
  // },
  // {
  //   eventId: "wnwg_270423_1800",
  //   title: "Worship night with guests",
  //   startDate: "04-27-2023",
  //   startTime: "18:00",
  //   mainText: "",
  //   moreInfo: false,
  //   type: nvgType,
  // },
  // {
  //   eventId: "nvg_040523_1800",
  //   title: nvgTitle,
  //   startDate: "05-04-2023",
  //   startTime: "19:00",
  //   mainText: nvgText,
  //   moreInfo: false,
  //   type: nvgType,
  // },
  // {
  //   eventId: "nvg_110523_1800",
  //   title: nvgTitle,
  //   startDate: "05-11-2023",
  //   startTime: nvgStartTime,
  //   mainText: nvgText,
  //   moreInfo: false,
  //   type: nvgType,
  // },
  {
    eventId: "nvg_180523_1800",
    title: nvgTitle,
    startDate: "2023-05-18",
    // startDate: "05-18-2023",
    startTime: nvgStartTime,
    mainText: nvgText,
    moreInfo: false,
    type: nvgType,
  },
  {
    eventId: "nvg_250523_1800",
    title: nvgTitle,
    startDate: "2023-05-25",
    startTime: nvgStartTime,
    mainText: nvgText,
    moreInfo: false,
    type: nvgType,
  },
  {
    eventId: "him_010623_1800",
    title: '"HIM"',
    startDate: "2023-06-01",
    endDate: "06-04-2023",
    // mainText: 'The worship conference "HIM" is taking place 1-4th of June in Örebro Sweden. 4 days of intense worship togeher with believers from around Sweden, Europe and USA. Special guest and worship leader is Eniola Abioye (Maverick, Upper Room, Greenhouse Circuit Riders) To apply and find out more, visit our conference site.',
    mainText: "",
    moreInfo: true,
    // url: "/coming-events/event/HIM-conference",
    type: "conference",
  },
  {
    eventId: "nvg_080623_1800",
    title: nvgTitle,
    startDate: "2023-06-08",
    startTime: nvgStartTime,
    mainText: nvgText,
    moreInfo: false,
    type: nvgType,
  },
  {
    eventId: "nvg_150623_1800",
    title: nvgTitle,
    startDate: "2023-06-15",
    startTime: nvgStartTime,
    mainText: nvgText,
    moreInfo: false,
    type: nvgType,
  },
];