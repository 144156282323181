import { Component, OnInit } from '@angular/core';
import { sessionsData } from 'src/app/models/sessions';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  allSessions: Sessions[] = sessionsData;
  session: Sessions = undefined;

  constructor(private helperService: HelperService) {

  }

  ngOnInit(): void {
    this.helperService.selectedSession.subscribe((selected) => this.session = selected);
    // console.log("Session:", this.session);

    if (this.session !== undefined) {
      this.session = this.session;
    } else {
      this.session = this.allSessions[0];
    }
  }

  videoSource(): string {
    if (this.session !== undefined) {
      return this.session.videoSrc;
    } else {
      return this.allSessions[0].videoSrc;
    }
  }
}
