import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LyricsService {

  selectedLyricsId = new BehaviorSubject<string>("");
  editStatus = new Subject();

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getAllLyrics(): Observable<Lyrics[]> {
    return this.http.get<Lyrics[]>(this.baseUrl + 'api/1.0/lyrics');
  }

  getLyricsById(lyricsId: string): Observable<Lyrics> {
    return this.http.get<any>(this.baseUrl + 'api/1.0/lyrics/' + lyricsId);
  }

  // getLyricsBySelectedId(): Observable<Lyrics> {
  //   return this.http.get<any>(this.baseUrl + 'api/1.0/lyrics/' + this.selectedLyricsId);
  // }

  // getFirstLyricsInList(): Lyrics {
  //   let returnValue: Lyrics;
  //   const all = this.http.get<Lyrics[]>(this.baseUrl + 'api/1.0/lyrics');
  //   all.subscribe(x => { returnValue = x[0] });

  //   return returnValue;
  // }

  addLyric(body: Lyrics) {
    return this.http.post<any>(this.baseUrl + 'api/1.0/lyrics/', body);
  }

  editLyric(id: string, body: Lyrics) {
    const request: Lyrics = {
      id: id,
      title: body.title,
      songWriters: body.songWriters,
      youtubeLink: body.youtubeLink,
      lyricSection: body.lyricSection,
    };

    return this.http.put<any>(this.baseUrl + 'api/1.0/lyrics/' + id, request);
  }

  deleteLyric(id: string) {
    return this.http.delete<any>(this.baseUrl + 'api/1.0/lyrics/' + id);
  }

  changeLyricsId(id: string) {
    this.selectedLyricsId.next(id);
  }
}
