import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EMPTY, empty, of } from "rxjs";
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})
export class AuthService {



  constructor(private router: Router, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private jwtHelper: JwtHelperService) {
    this.userSubject = new BehaviorSubject<ILoggedInUser>(null);
    this.loggedInUser = this.userSubject.asObservable();
  }

  private userSubject: BehaviorSubject<ILoggedInUser>;
  public loggedInUser: Observable<ILoggedInUser>;

  private jwtTokenName = 'jwt';
  private refreshTokenName = 'refreshToken';

  private refreshTokenTimeout;

  public get userValue(): ILoggedInUser {
    return this.userSubject.value;
  }

  login(email: string, password: string) {


    localStorage.removeItem(this.jwtTokenName);
    localStorage.removeItem(this.refreshTokenName);

    return this.http.post<any>(this.baseUrl + 'api/1.0/auth', {
      email: email,
      password: password
    })
      .pipe(map((x: ITokenAndRefreshTokenDto) => {

        // console.log('login response', x);
        this.setToken(x);
        this.startRefreshTokenTimer();
        return x;
      }));
  }

  // set new loggedInUser
  setLoggedInUserFromJwt() {

    let jwt = localStorage.getItem(this.jwtTokenName);
    if (jwt) {
      let decodedJwt = this.jwtHelper.decodeToken(jwt);
      // console.log('jwt decoded', decodedJwt);
      this.userSubject.next({ firstName: decodedJwt.firstName } as ILoggedInUser);
    } else {
      this.userSubject.next(null);
    }
  }

  logout() {

    this.http.post<any>(this.baseUrl + 'api/1.0/token/revoke', {}, { withCredentials: true }).subscribe(x => { }, e => { }, () => {
      localStorage.removeItem(this.jwtTokenName);
      localStorage.removeItem(this.refreshTokenName);
    });

    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(['/admin']);

  }

  // save jwt localStorage
  setToken(token: ITokenAndRefreshTokenDto) {

    localStorage.setItem(this.jwtTokenName, token.accessToken);
    localStorage.setItem(this.refreshTokenName, token.refreshToken);
    this.setLoggedInUserFromJwt();
  }

  public refreshToken() {
    // console.log('refresh token starting..');

    let jwt = localStorage.getItem(this.jwtTokenName);
    let refreshToken = localStorage.getItem('refreshToken');

    if (!jwt || !refreshToken) {
      return EMPTY;
    }
    return this.http.post<any>(this.baseUrl + 'api/1.0/token/refresh',
      {
        accessToken: jwt,
        refreshToken: refreshToken
      } as ITokenAndRefreshTokenDto, { withCredentials: true })
      .pipe(map((x: ITokenAndRefreshTokenDto) => {
        // console.log('refreshToken', x);
        this.setToken(x);
        this.startRefreshTokenTimer();
        return x;
      }),
        catchError((err, caught) => {

          // console.log('refreshToken pipe error ', err);
          if (err.error === 'Refresh token expired.' || err.error === 'Bad refresh token.') {
            this.logout();
          }

          return EMPTY;
        }));
  }

  private startRefreshTokenTimer() {

    let decodedJwt = this.jwtHelper.decodeToken(localStorage.getItem('jwt'));
    const exp = new Date(decodedJwt.exp * 1000);
    const timeout = exp.getTime() - Date.now() - (60 * 1000);
    // console.log('refresh timeout in minutes', timeout / 1000 / 60);

    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  resetPasswordInit(email: string) {
    return this.http.post('/api/1.0/auth/reset-password',
      { email: email });
  }

  resetPassword(email: string, password: string, token: string) {
    return this.http.put('/api/1.0/auth/reset-password',
      { email: email, password: password, token: token });
  }
}
