import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css', '../../../../styles.css']
})
export class LoginFormComponent implements OnInit {

  constructor(private router: Router, private http: HttpClient,private authService:AuthService ) { }

  ngOnInit(): void {
  }

  invalidLogin = false;


  login(form: NgForm) {
    const credentials:any = form.value;

    // console.log('credentials', credentials);

    this.authService.login(credentials.email, credentials.password)
      .subscribe(response => {
        this.invalidLogin = false;
        this.router.navigate(['/admin/main']) // don't know if this is correct...
      }, err => {
        this.invalidLogin = true;
      }
    );
  }

  logOut() {
    localStorage.removeItem('jwt');
  }
  
}
