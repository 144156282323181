import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-him-conference',
  templateUrl: './him-conference.component.html',
  styleUrls: ['./him-conference.component.scss', '../../../../../../styles.css']
})
export class HIMConferenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
