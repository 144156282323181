import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { LyricsService } from 'src/app/services/lyrics.service';

@Component({
  selector: 'app-add-lyrics-modal',
  templateUrl: './add-lyrics-modal.component.html',
  styleUrls: ['./add-lyrics-modal.component.scss']
})
export class AddLyricsModalComponent implements OnInit {

  // id: string = '';
  // title: string = '';
  // songWriters: string = '';
  // lyricSection: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private lyricsService: LyricsService,
    private notifier: NotifierService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(data: NgForm) {
    // console.log("data:", data.value);
    this.lyricsService.addLyric(data.value).subscribe();
    this.notifier.notify('success', "A new lyric has been created.");
    this.activeModal.close();
  }
}
