import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-user-profile-edit',
  templateUrl: './user-profile-edit.component.html',
  styleUrls: ['./user-profile-edit.component.css']
})
export class UserProfileEditComponent implements OnInit {

  constructor(private userService:UserService ,private authService:AuthService ) { }


  user: any = null;


  ngOnInit(): void {
    this.reload();
  }

  reload() {
    this.userService.getCurrentUser().subscribe(x => {
      this.user = x;
    });
  }

  update() {
    this.userService.updateUser(this.user).subscribe(x => {
      this.authService.refreshToken().subscribe(y => {

      });
    });
  }
  
}
