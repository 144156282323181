import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss', '../../../../styles.css']
})
export class AboutComponent implements OnInit {

  innerWidth: any;
  active = 1;
  H5H6Breakpoint: string = "450";

  constructor() { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  renderH5() {
    if (this.innerWidth >= this.H5H6Breakpoint) {
      return true
    } else {
      return false;
    }
  }

  renderH6() {
    if (this.innerWidth < this.H5H6Breakpoint) {
      return true
    } else {
      return false;
    }
  }
}
