import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-create-user-form',
  templateUrl: './create-user-form.component.html',
  styleUrls: ['./create-user-form.component.css', '../../../../styles.css']
})
export class CreateUserFormComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router, private notifier: NotifierService) { }

  user = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  }

  ngOnInit(): void {
  }

  validationErrors: string[] = [];

  createUser() {
    this.validationErrors = [];

    const credentials = JSON.stringify(this.user);

    // console.log('credentials', credentials);

    this.http.post('/api/1.0/user', credentials, {
      headers: new HttpHeaders({
        "Content-Type": 'application/json'
      })
    }).subscribe(response => {

      this.user.email = '';
      this.user.password = '';
      this.user.firstName = '';

      this.notifier.notify('success', 'Användare skapad.');

    }, err => {
      console.warn('error', err);

      let e = err.error;

      if (!e) {
        return;
      }

      for (const property in e) {
        e[property].forEach(x => this.validationErrors.push(x));
      }
    });
  }

}
