import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PayPalService {

  planId: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  onTransactionComplete(body: PayPalPaymentConfirmed) {
    this.http.post<any>(this.baseUrl + 'api/1.0/PayPal/', body);
  }

  getWebhookTest(): Observable<WebhookTest[]> {
    return this.http.get<any>(this.baseUrl + 'api/1.0/PayPal/');
  }

  createTest() {
    return this.http.post<any>(this.baseUrl + 'api/1.0/PayPal/test/', null);
  }

  createPlan(body: CreatePlanBody) {
    // console.log(body);
    return this.http.post<any>(this.baseUrl + 'api/1.0/PayPal/createPlan/', body);
  }
}
