export const musicData: MusicData[] = [
  {
    albumId: "rise",
    title: "Rise",
    imgSrc: "../../../assets/album-covers/RISE.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/6uasvCVoQDG0IzhCxIwdFV?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/se/album/rise-live-from-noise-village-single/1707383573?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "single",
    darkOrBrightCover: "bright",
    // lyricsPdfSrc: {
    //   lyrics: "",
    //   lyricsChords: "",
    // }
  },
  {
    albumId: "fullness",
    title: "Fullness",
    imgSrc: "../../../assets/album-covers/FULLNESS_LIGHT.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/4PhGNUtyXoyfgrA77xbLIM?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/se/album/fullness-live-from-noise-village-single/1694323473?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "single",
    darkOrBrightCover: "bright",
    lyricsPdfSrc: {
      lyrics: "https://drive.google.com/file/d/16y0ah7FYVthMdz4yF990phT43Q9v_bjj/view",
      lyricsChords: "https://drive.google.com/file/d/170RHloaE9JKfq6-qjHitwyd8R285HGnl/view",
    }
  },
  {
    albumId: "fixYourEyes",
    title: "Fix Your Eyes (feat. Rebecka Almonte)",
    imgSrc: "../../../assets/album-covers/FIX_YOUR_EYES_2.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/2Uluunt4yWUJWLh0OgTxSH?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/se/album/fix-your-eyes-feat-rebecka-almonte-live-from-noise/1690431273?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "single",
    darkOrBrightCover: "bright",
    lyricsPdfSrc: {
      lyrics: "https://drive.google.com/file/d/16VUnsq3lv0dus3e7ureXBUBVV-fgrbLZ/view",
      lyricsChords: "https://drive.google.com/file/d/16VBgmaosnTKpOVITM7Na0LKL3zv8t8Eg/view",
    }
  },
  {
    albumId: "nuArJagDin",
    title: "Nu är jag din",
    imgSrc: "../../../assets/album-covers/NU_AR_JAG_DIN.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/0Hj4RGloYs1gZCruH8YlFP?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/se/album/nu-%C3%A4r-jag-din-live-from-noise-village-single/1683502332?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "single",
    darkOrBrightCover: "bright",
    lyricsPdfSrc: {
      lyrics: "https://drive.google.com/file/d/16OawJUacG9uFL-4Nb0TNUyd8z5gRuKAe/view?usp=sharing",
      lyricsChords: "https://drive.google.com/file/d/16LzFxiw63RWA5HSQrRheOuROv-qgkn_X/view?usp=sharing",
    }
  },
  {
    albumId: "jagVillFoljaJesus",
    title: "Jag vill följa Jesus",
    imgSrc: "../../../assets/album-covers/JAG_VILL_FOLJA_JESUS.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/0GOHfd27jIhPYHnTncYP0d?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/se/album/jag-vill-f%C3%B6lja-jesus-live-from-noise-village-single/1676328630?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "single",
    darkOrBrightCover: "bright",
    lyricsPdfSrc: {
      lyrics: "https://drive.google.com/file/d/16Dn6f31lJ3B9YUHEI5kF-bduZbopsGNO/view?usp=drivesdk",
      lyricsChords: "https://drive.google.com/file/d/16Gj-CMvrwJIjp2WDuJVXSEP8HTd_LvOm/view?usp=drivesdk",
    }
  },
  {
    albumId: "shalomInEveryStorm",
    title: "Shalom in Every Storm",
    imgSrc: "../../../assets/album-covers/SHALOM_IN_EVERY_STORM.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/2eD93Tv0UnCVlM2bXb9YJh?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/se/album/shalom-in-every-storm-single/1566423245?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "single",
    darkOrBrightCover: "bright",
  },
  {
    albumId: "vantarHarPaDig",
    title: "Väntar här på dig (Psalm 130)",
    imgSrc: "../../../assets/album-covers/VANTAR_HAR_PA_DIG.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/6Av7keFlZCX1jZrkF06GI2?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/se/album/v%C3%A4ntar-h%C3%A4r-p%C3%A5-dig-psalm-130-single/1560725987?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "single",
    darkOrBrightCover: "dark",
  },
  {
    albumId: "onlyYou",
    title: "Only You",
    imgSrc: "../../../assets/album-covers/ONLY_YOU.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/6ns1qWAHs8PmXF00bNWd3y?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/se/album/only-you-single/1543976313?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "single",
    darkOrBrightCover: "bright",
  },
  {
    albumId: "identitet",
    title: "Identitet",
    imgSrc: "../../../assets/album-covers/IDENTITET.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/6aPyFTqPR4dG9MM6h20VB3?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/us/album/identitet/1484317305?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "album",
    darkOrBrightCover: "bright",
  },
  {
    albumId: "brinner",
    title: "Brinner",
    imgSrc: "../../../assets/album-covers/BRINNER.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/1DhvSBZ0zz7UUxywPoS7Pn?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/us/album/brinner-single/1481961709?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "single",
    darkOrBrightCover: "dark",
  },
  {
    albumId: "duArDenDuAr",
    title: "Du är den Du är",
    imgSrc: "../../../assets/album-covers/DU_AR_DEN_DU_AR.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/1iH2RxncdppTQNALMUbRJ5?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/se/album/du-%C3%A4r-den-du-%C3%A4r-single/1481470043?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "single",
    darkOrBrightCover: "dark",
  },
  {
    albumId: "nothingButGlory",
    title: "Nothing but Glory (feat. Mathias Danielsson)",
    imgSrc: "../../../assets/album-covers/NOTHING_BUT_GLORY.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/222oDqxNmlE0k7zTpUbJ4w?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/se/album/nothing-but-glory-feat-mathias-danielsson-single/1200854777?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "single",
    darkOrBrightCover: "bright",
  },
  {
    albumId: "comeTearDownTheWalls",
    title: "Come and Tear Down the Walls (feat. Jenny Wahlström)",
    imgSrc: "../../../assets/album-covers/COME_AND_TEAR_DOWN_THE_WALLS.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/3s7b9EI4C4WzFdaC3r5W0j?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/se/album/come-and-tear-down-the-walls-feat-jenny-wahlstr%C3%B6m-single/1190118902?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "single",
    darkOrBrightCover: "dark",
  },
  {
    albumId: "heavensDeclaration",
    title: "Heaven's Declaration (feat. Mark Alan Schoolmeesters)",
    imgSrc: "../../../assets/album-covers/HEAVENS_DECLARATION.jpg",
    spotifySrc: "https://open.spotify.com/embed/album/6tmNhzkFOCg2itiQLA2gNx?utm_source=generator",
    appleSrc: "https://embed.music.apple.com/se/album/heavens-declaration-feat-mark-alan-schoolmeesters-single/1181137076?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=dark",
    type: "single",
    darkOrBrightCover: "bright",
  },
];


// -D8j*yhBv
