import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: [
    '../../../../styles.css',
    './resources.component.scss'
  ]
})
export class ResourcesComponent implements OnInit {

  // innerWidth: number;
  // active: "music" | "sessions" = "music";
  // active = 1;

  constructor(private router: Router) { }

  ngOnInit(): void {
    // this.innerWidth = window.innerWidth;
  }

  // setTab(tab: number) {
  //   console.log(tab);
  //   this.active = tab;
  // }

  // setTab(tab: "sessions" | "music") {
  //   console.log(tab);
  //   this.active = tab;
  // }

  // @HostListener('window:resize', ['$event'])
  // onResize() {
  //   this.innerWidth = window.innerWidth;
  // }

  // sessionArtSize() {
  //   if (this.innerWidth <= 450) {
  //     return { width: "280px" };
  //   }
  //   if (this.innerWidth > 450) {
  //     return { width: "334px" };
  //   }
  // }

  // headerSize() {
  //   if (this.innerWidth <= 450) {
  //     return { width: "280px" };
  //   }
  //   if (this.innerWidth > 450) {
  //     return { width: "334px" };
  //   }
  // }
}
