import { AfterContentInit, AfterViewInit, Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { HelperService } from './services/helper.service';
import { Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  title = 'app';
  active = 1;
  innerWidth: any;

  // If changed, these values needs to be changed in music, sessions and resources as well
  H5H6Breakpoint: string = "450";
  H4H5Breakpoint: string = "1200";

  constructor(
    private helpService: HelperService,
    private router: Router,
    private offcanvasService: NgbOffcanvas,
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    // this.active = 1;
    this.helpService.activeTab.subscribe((tab) => { this.active = tab });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  renderH4() {
    if (this.innerWidth >= this.H4H5Breakpoint) {
      return true
    } else {
      return false;
    }
  }

  renderH5() {
    if (this.innerWidth >= this.H5H6Breakpoint) {
      return true
    } else {
      return false;
    }
  }

  renderH6() {
    if (this.innerWidth < this.H5H6Breakpoint) {
      return true
    } else {
      return false;
    }
  }

  open(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'end' })
  }

  get getIsExpanded() {
    return this.helpService.getIsSideMenuOpen;
  }

  closeSideMenu(content: TemplateRef<any>) {
    return this.helpService.closeSideMenu(content);
  }

  isTabContent() {
    if (this.router.url === "/music" || this.router.url === "/sessions") {
      return true;
    } else {
      return false;
    }
  }

  setActiveTab(tab: ActiveTab) {
    this.helpService.setActiveTab(tab);
  }

  get isMainApp(): boolean {
    if (
      this.router.url !== "/admin" &&
      this.router.url !== "/admin/user" &&
      this.router.url !== "/admin/management" &&
      this.router.url !== "/admin/main" &&
      this.router.url !== "/admin/create-user"
    ) {
      return true;
    } else {
      return false;
    }
  }
}
