import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { musicData } from 'src/app/models/music';
import { sessionsData } from 'src/app/models/sessions';
import { HelperService } from 'src/app/services/helper.service';
import { LyricsService } from 'src/app/services/lyrics.service';
import { MusicService } from 'src/app/services/music.service';

@Component({
  selector: 'app-nav-menu-side',
  templateUrl: './nav-menu-side.component.html',
  styleUrls: ['./nav-menu-side.component.css', '../../../../styles.css']
})
export class NavMenuSideComponent implements OnInit {

  lyrics: Lyrics[] = [];
  albumData = musicData;
  sessions = sessionsData;

  @Input() offcanvasContent: TemplateRef<any> | undefined = undefined;

  constructor(
    private helpService: HelperService,
    private lyricsService: LyricsService,
    private musicService: MusicService,
  ) { }

  ngOnInit(): void {
    this.lyricsService.getAllLyrics().subscribe((lyrics) => (this.lyrics = lyrics));
  }

  isSideMenuOpen() {
    return this.helpService.getIsSideMenuOpen;
  }

  closeSideMenu(activeTab: ActiveTab) {
    this.helpService.setActiveTab(activeTab);
    this.helpService.closeSideMenu(this.offcanvasContent);
  }

  closeSideMenuMusic(activeTab: ActiveTab) {
    this.helpService.setActiveTab(activeTab);
    this.helpService.closeSideMenu(this.offcanvasContent);
  }

  closeSideMenuSession(session: Sessions) {
    this.helpService.selectSession(session);
    this.helpService.closeSideMenu(this.offcanvasContent);
}

  setAlbumId(id: AlbumId) {
    this.helpService.setActiveTab(2);
    this.musicService.setAlbumId(id);
  }

  setLyricsId(id: string) {
    this.lyricsService.changeLyricsId(id);
  }

  get getLyrics(): Lyrics[] {
    return this.lyrics;
  }

}
