import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  modal: Subject<any> = new Subject<any>();

  orderDetails = new BehaviorSubject<any>(undefined);
  
  constructor() { }
  
  setOrderDetails(details: any) {
    this.orderDetails.next(details);
  }
}
