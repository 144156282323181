import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-nv-gathering-modal',
  templateUrl: './nv-gathering-modal.component.html',
  styleUrls: ['./nv-gathering-modal.component.scss', '../../../../../../styles.css']
})
export class NvGatheringModalComponent implements OnInit, AfterViewInit {

  closeResult = '';
  @Input() title;
  @ViewChild("content") modalContent: ElementRef<any>;
  modalRef: NgbModalRef;

  constructor(private helperService: HelperService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    // this.open();
    // console.log(this.title);
  }

  ngAfterViewInit(): void {
    // this.open();
    // if (this.modalContent) {
    //   this.modalRef = this.modalService.open(this.modalContent, {
    //     // ariaLabelledBy: 'modal-basic-title',
    //   });
    //   // this.modalRef.dismiss();
    // }
  }

  // open = (): void => {
  //   // this.modalRef = this.modalService.open(this.modalContent, {
  //   //   // ariaLabelledBy: 'modal-basic-title',
  //   // });
  //   // this.modalRef.result.then(
  //   //   (resolved) => {},
  //   //   (dismissed) => {
  //   //     this.OnClose();
  //   //   }
  //   // );
  // };

  OnClose = () => {
    // this.modalRef.close();
    this.helperService.selectEvent(undefined);
    this.activeModal.dismiss('Cross click');
  };
}
