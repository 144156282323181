import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-management-page',
  templateUrl: './management-page.component.html',
  styleUrls: ['./management-page.component.css']
})
export class ManagementPageComponent implements OnInit {

  constructor() { }

  searchTerm = '';
  users: any[] = [];
  selectedUser = null;
  selectedRole: string = '';

  ngOnInit(): void {
    // this.reloadUsers();
  }

  // selectUser(user) {
  //   this.selectedUser = user;

  //   if (!user) {
  //     return;
  //   }

  //   this.reloadUser(user.userId);
  // }

  // reloadUser(userId) {
  //   this.userService.getUserById(userId).subscribe(x => {

  //     this.selectedUser = x;
  //   });
  // }

  // reloadUsers() {
  //   this.userService.findUsers(this.searchTerm).subscribe(x => {
  //     this.users = x;
  //   });
  // }

  // userCreated() {
  //   this.selectUser(null);
  //   this.reloadUsers();
  // }

  // removeUser(user) {
  //   console.log('remove', user);
  //   this.userService.removeUser(user.userId).subscribe(x => {
  //     this.reloadUsers();
  //   });
  // }

}
