import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotifierModule } from 'angular-notifier';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/Pages/home/home.component';
import { NavMenuTopComponent } from './components/NavMenu/nav-menu-top/nav-menu-top.component';
import { NavMenuSideComponent } from './components/NavMenu/nav-menu-side/nav-menu-side.component';
import { MusicComponent } from './components/Pages/music/music.component';
import { LyricsComponent } from './components/Pages/lyrics/mainPage/lyrics.component';
import { SupportNVPageComponent } from './components/Pages/support-nvpage/support-nvpage.component';
import { ResourcesComponent } from './components/Pages/resources/resources.component';
import { ButtonComponent } from './components/commonComponents/button/button.component';
import { GiveButtonComponent } from './components/commonComponents/give-button/give-button.component';
import { LyricsContentComponent } from './components/Pages/lyrics/lyrics-components/lyrics-content.component';
import { CreateUserFormComponent } from './components/admin/create-user-form/create-user-form.component';
import { ManagementPageComponent } from './components/unused/management-page/management-page.component';
import { LoginFormComponent } from './components/admin/login-form/login-form.component';
import { AdminComponent } from './components/admin/admin.component';
import { UserProfileEditComponent } from './components/unused/user-profile-edit/user-profile-edit.component';
import { AdminNavMenuTopComponent } from './components/NavMenu/admin-nav-menu-top/admin-nav-menu-top.component';
import { ResetPasswordComponent } from './components/unused/reset-password/reset-password.component';
import { MainAdminComponent } from './components/admin/main-admin/main-admin.component';
import { LyricsModalComponent } from './components/commonComponents/modals/lyrics-modal/lyrics-modal.component';
import { AddLyricsModalComponent } from './components/commonComponents/modals/lyrics-modal/add-lyrics/add-lyrics-modal.component';
import { EditLyricsModalComponent } from './components/commonComponents/modals/lyrics-modal/edit-lyrics/edit-lyrics-modal.component';
import { DeleteLyricsModalComponent } from './components/commonComponents/modals/lyrics-modal/delete-lyrics-modal/delete-lyrics-modal.component';
import { AboutComponent } from './components/Pages/about/about.component';
import { ComingEventsComponent } from './components/Pages/coming-events/coming-events.component';

import { AuthGuard } from './services/auth-guard.service';
import { HelperService } from './services/helper.service';
import { LyricsService } from './services/lyrics.service';
import { AuthService } from './services/auth.service';
import { appInitializer } from './app.initializer';
import { Interceptor } from './interceptors/interceptor';
import { UserFullNamePipe } from './pipes/user-full-name.pipe';
import { PaypalModalComponent } from './components/Pages/support-nvpage/modals/paypal-modal/paypal-modal.component';
import { SafePipe } from './pipes/safe.pipe';
import { EllipsisDirective } from './directives/ellipsis-directive';
import { HIMConferenceComponent } from './components/Pages/coming-events/special-events/him-conference/him-conference.component';
import { SessionsComponent } from './components/Pages/sessions/sessions.component';
import { ClickOutsideDirective } from './directives/clickOutside-directive';
import { NvGatheringModalComponent } from './components/Pages/coming-events/modals/nv-gathering-modal/nv-gathering-modal.component';
import { PayPalTestComponent } from './components/Pages/test/pay-pal-test/pay-pal-test.component';
import { ModalsService } from './services/modals.service';

export function tokenGetter() {
  return localStorage.getItem('jwt');
}

@NgModule({
  declarations: [
    AppComponent,

    // Main Page
    NavMenuTopComponent,
    NavMenuSideComponent,
    HomeComponent,
    ComingEventsComponent,
    MusicComponent,
    LyricsComponent,
    ResourcesComponent,
    SupportNVPageComponent,
    ButtonComponent,
    GiveButtonComponent,
    LyricsContentComponent,
    AboutComponent,

    // Admin Page
    AdminNavMenuTopComponent,
    AdminComponent,
    MainAdminComponent,
    LoginFormComponent,
    CreateUserFormComponent,
    LyricsModalComponent,
    AddLyricsModalComponent,
    EditLyricsModalComponent,
    DeleteLyricsModalComponent,

    PaypalModalComponent,

    // Tools
    SafePipe,
    EllipsisDirective,
    ClickOutsideDirective,

    // Special events
    HIMConferenceComponent,
    
    // Unused
    UserProfileEditComponent,
    UserFullNamePipe,
    ManagementPageComponent,
    ResetPasswordComponent,
    SessionsComponent,
    NvGatheringModalComponent,
    PayPalTestComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10
        }
      },
      theme: 'material',
      behaviour: {
        autoHide: 6000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 10
      },
      animations: {
        enabled: true,
        show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease'
        },
        hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50
        },
        shift: {
          speed: 300,
          easing: 'ease'
        },
        overlap: 150
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["localhost:5000"],
        blacklistedRoutes: []
      }
    }),
    RouterModule.forRoot([
      { path: '', component: HomeComponent },
      { path: 'coming-events', component: ComingEventsComponent },
      { path: 'coming-events/event/HIM-conference', component: HIMConferenceComponent },
      // { path: 'resources', component: ResourcesComponent },
      { path: 'sessions', component: SessionsComponent },
      { path: 'music', component: MusicComponent },
      // { path: 'lyrics', component: LyricsComponent },
      { path: 'about', component: AboutComponent },
      { path: 'giving', component: SupportNVPageComponent },

      { path: 'admin', component: AdminComponent },
      { path: 'admin/login', component: LoginFormComponent },
      { path: 'admin/main', component: MainAdminComponent },
      { path: 'admin/user', component: UserProfileEditComponent, canActivate: [AuthGuard] },
      { path: 'admin/management', component: ManagementPageComponent, canActivate: [AuthGuard] },
      { path: 'admin/reset-password', component: ResetPasswordComponent },
      { path: 'admin/create-user', component: CreateUserFormComponent },

      // { path: 'test', component: PayPalTestComponent }
      
    ], { relativeLinkResolution: 'legacy', useHash: true, onSameUrlNavigation: "reload" }),
    NgbModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
  ],
  // exports: [RouterModule],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService] },
    AuthGuard,
    // { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    LyricsService,
    HelperService,
    ModalsService,
    NgbActiveModal,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
