import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';
import { Observable,of } from 'rxjs';
import { map ,tap} from 'rxjs/operators';

@Pipe({
  name: 'userFullName'
})
export class UserFullNamePipe implements PipeTransform {

  constructor(private userService: UserService ) {}

  transform(userId:string): Observable<string> {

    if (!userId) {
      return of();
    }

    return this.userService.getUserById(userId).pipe(
      tap(x => {
      }),
      map((x:any) => {
        return x.firstName+' '+x.lastName;
      })
    );
  }
}
