import { Injectable, Inject } from '@angular/core';
import { tap, finalize, catchError } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotifierService } from 'angular-notifier';
//import { RouterModule, Routes, Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private notifierService: NotifierService, @Inject('BASE_URL') private baseUrl: string) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const isApiUrl = request.url.startsWith(this.baseUrl + 'api/');
    let jwt = localStorage.getItem('jwt');

    if (isApiUrl && jwt) {
      request = request.clone({
        setHeaders: { Authorization: 'Bearer ' + jwt}
      });
    }

    return next.handle(request).pipe(
      tap(
        error => {
          if (error instanceof HttpErrorResponse) {
            let msg = 'Error';
            let msgType = 'error';

            if (error.error && typeof error.error === 'string') {
              if (error.error.length < 200) {
                msg = error.error;
              }
            }

            let e = error.error;
        
            switch (error.status) {
              case 400:
                if (e.errors && error.error.title === 'One or more validation errors occurred.') {
                  msg = '';
                  // console.log('here 1');
                  for (const property in e.errors) {
                    // console.log('property', property);
                    let arr = e.errors[property];
                    if (Array.isArray(arr)) {
                    
                      for (const x of arr) {
                        msg += x ;
                      };
                    }
                  }
                }else if ((typeof e) ==='string' ) {
                  msg = e;
                }
                break;

              case 401:
                break;

              case 403:
                msg = 'Du har ingen behörighet till denna resurs';
                break;

              case 404:
                msg = 'Det finns ingen resurs på denna adress.';
                break;

              case 500:
                if (error.error && error.error.Message && error.error.Message.length < 300) {
                  msg = error.error.Message;
                }
                break;

              default:
            }
          
            this.notifierService.notify(msgType, msg);
          }
        }
      ),

      finalize(() => {
      })
    );

  }
}
