import { Component, HostListener, OnInit } from '@angular/core';
import { LyricsService } from 'src/app/services/lyrics.service';

@Component({
  selector: 'app-lyrics',
  templateUrl: './lyrics.component.html',
  styleUrls: [
    '../../../../../styles.css',
    './lyrics.component.scss',
  ]
})
export class LyricsComponent implements OnInit {

  searchValue: string = "";
  lyrics: Lyrics[] = [];
  selectedLyric?: Lyrics;
  selectedLyricsId: string = "";

  innerWidth: any;
  breakpoint: string = "767";

  isCollapsed: boolean = true;

  constructor(private lyricsService: LyricsService) {

  }

  ngOnInit(): void {
    this.lyricsService.getAllLyrics().subscribe((lyrics) => (this.lyrics = lyrics));
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    // console.log(this.innerWidth)
    this.innerWidth = window.innerWidth;
  }

  selectLyricsId(lyricsId: string) {
    this.lyricsService.changeLyricsId(lyricsId);
    this.isCollapsed = true;
  }

  get filterList(): Lyrics[] {
    const filtered = this.lyrics?.filter(e => {
      return (
        this.searchValue === "" ||
        e.title.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    });

    return filtered;
  }
}
