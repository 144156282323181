import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MusicService {

  selectedAlbumId = new BehaviorSubject<AlbumId>("");
  
  constructor() { }

  setAlbumId(id: AlbumId) {
    this.selectedAlbumId.next(id);
  }
}
