export const sessionsData: Sessions[] = [
  {
    sessionId: "rise",
    title: "Rise (feat. Micke Fhinn)",
    description: "",
    coverSrc: "../../../assets/sessions/RISE.jpg",
    videoSrc: 'https://www.youtube.com/embed/zRP5xYjXNCs?si=L-zhUrZf9mq5NNh_',
    time: "",
    duration: "",
    type: "single",
  },
  {
    sessionId: "fullness",
    title: "Fullness (feat. Lucas Galovan)",
    description: "",
    coverSrc: "../../../assets/sessions/FULLNESS.jpg",
    videoSrc: 'https://www.youtube.com/embed/nsvu0s15GIc',
    time: "",
    duration: "",
    type: "single",
  },
  {
    sessionId: "fixYourEyes",
    title: "Fix Your Eyes (feat. Rebecka Almonte)",
    description: "",
    coverSrc: "../../../assets/sessions/FIX_YOUR_EYES.jpg",
    videoSrc: 'https://www.youtube.com/embed/85MqEn5qeYc',
    time: "",
    duration: "",
    type: "single",
  },
  {
    sessionId: "nuArJagDin",
    title: "Nu är jag Din",
    description: "",
    coverSrc: "../../../assets/sessions/NU_AR_JAG_DIN_SESSION_COVER.jpg",
    videoSrc: 'https://www.youtube.com/embed/y1YnVQbFC48',
    time: "",
    duration: "",
    type: "single",
  },
  {
    sessionId: "jagVillFoljaJesus",
    title: "Jag vill följa Jesus",
    description: "",
    coverSrc: "../../../assets/sessions/JAG_VILL_FOLJA_JESUS_SESSION_COVER.jpg",
    videoSrc: 'https://www.youtube.com/embed/tNtNwcGY_IY?start=10',
    time: "",
    duration: "",
    type: "single",
  },
];

