import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin-nav-menu-top',
  templateUrl: './admin-nav-menu-top.component.html',
  styleUrls: ['./admin-nav-menu-top.component.scss']
})
export class AdminNavMenuTopComponent implements OnInit {

  constructor(private authService: AuthService) { }

  loggedInUser: ILoggedInUser | null = null;
  
  ngOnInit(): void {
    this.authService.loggedInUser.subscribe(x => {
      this.loggedInUser = x;
    });
  }

  public logOut = () => {
    this.authService.logout();
  }

}
