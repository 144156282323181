import { Component, HostListener, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LyricsService } from 'src/app/services/lyrics.service';

@Component({
  selector: 'app-lyrics-content',
  templateUrl: './lyrics-content.component.html',
  styleUrls: [
    '../../../../../styles.css',
    '../mainPage/lyrics.component.scss',
    './lyrics-content.component.scss',
  ]
})
export class LyricsContentComponent implements OnInit, OnChanges {

  selectedLyricsId: string | undefined;
  lyrics: Lyrics[] = [];
  // songWriters: string[];

  innerWidth: any;

  constructor(private lyricsService: LyricsService) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.lyricsService.selectedLyricsId.subscribe(e => this.selectedLyricsId = e);
    this.lyricsService.getAllLyrics().subscribe(e => {
      this.lyrics = e;
      if (this.selectedLyricsId === undefined || this.selectedLyricsId === "") {
        this.lyricsService.getAllLyrics().subscribe(e => this.selectedLyricsId = e[0].id);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.selectedLyricId.firstChange) {
      // console.log(changes.selectedLyricId.currentValue);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  youtubeSize() {
    if (this.innerWidth <= 430) {
      return { height: "169px", width: "300px" }
    }
    if (this.innerWidth > 430 && this.innerWidth <= 900) {
      return { height: "225px", width: "400px" }
    }
    else if (this.innerWidth > 900) {
      return { height: "315px", width: "560px" }
    }
    else {
      return { height: "315px", width: "560px" }
    }
  }

  showSongTitle() {
    const selectedLyrics = this.lyrics.find((e) => this.selectedLyricsId === e.id);

    return selectedLyrics?.title ?? "";
  }

  showSongWriters(): string {
    const selectedLyrics = this.lyrics.find((e) => this.selectedLyricsId === e.id);

    return selectedLyrics?.songWriters ?? "";
  }

  showYouTube() {
    const selectedLyrics = this.lyrics.find((e) => this.selectedLyricsId === e.id);

    if (selectedLyrics && selectedLyrics.youtubeLink && selectedLyrics.youtubeLink !== null) {
      return selectedLyrics.youtubeLink;
    } else {
      return undefined;
    }
  }

  returnLyrics(): string {
    const selectedLyrics = this.lyrics.find((e) => this.selectedLyricsId === e.id);

    return selectedLyrics?.lyricSection ?? "";
  }
}
