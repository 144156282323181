import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-give-button',
  templateUrl: './give-button.component.html',
  styleUrls: ['./give-button.component.scss']
})
export class GiveButtonComponent implements OnInit {

  @Input() text: string = "";
  @Input() class: string = "";
  @Output() btnClick = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  onClick(): void {
    this.btnClick.emit();
  }

}
