import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LyricsModalComponent } from '../../commonComponents/modals/lyrics-modal/lyrics-modal.component';


@Component({
  selector: 'app-main-admin',
  templateUrl: './main-admin.component.html',
  styleUrls: ['./main-admin.component.scss']
})
export class MainAdminComponent implements OnInit {
  
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  openLyricsModal() {
    this.modalService.open(LyricsModalComponent, { size: 'xl' })
  }
}
