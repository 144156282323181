import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PayPalService } from 'src/app/services/pay-pal.service';
import { PaypalModalComponent } from './modals/paypal-modal/paypal-modal.component';
import { OrderResponseBody, SubscriptionDetail, loadScript } from '@paypal/paypal-js';
import { PayPalNamespace } from '@paypal/paypal-js';

@Component({
  selector: 'app-support-nvpage',
  templateUrl: './support-nvpage.component.html',
  styleUrls: ['./support-nvpage.component.scss', '../../../../styles.css']
})
export class SupportNVPageComponent implements OnInit {
  
  currency: "EUR" | "USD" | "SEK" = "SEK";
  frequency: "single" | "subscription" = "single";
  provider = 1;
  subState: PaypalSubState = "default";
  amount: number | null = 0;
  innerWidth: any;
  
  // currencyAmount: {
  //   amount25: number;
  //   amount50: number;
  //   amount100: number;
  // }
  
  @ViewChild("singleAmountField") singleAmountField;
  @ViewChild("monthlyAmountField") monthlyAmountField;
    
  // public _reload = true;
  
  // private reload() {
  //   setTimeout(() => this._reload = false);
  //   setTimeout(() => this._reload = true);
  // }
    
  subscribeConfigs = {};
  selectedSub: string;
  
  myScriptElement: HTMLScriptElement;
  
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private paypalService: PayPalService,
  ) {

  }

  ngOnInit(): void {    
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  // setCurrency(value: "EUR" | "USD" | "SEK") {
  //   this.reload();
  //   this.currency = value;
  // }

  // setCurrencyAmount() {
  //   if (this.currency === "SEK") {
  //     this.currencyAmount.amount25 = 250;
  //     this.currencyAmount.amount50 = 500;
  //     this.currencyAmount.amount100 = 1000;
  //   }
  //   if (this.currency === "EUR" || this.currency === "USD") {
  //     this.currencyAmount.amount25 = 25;
  //     this.currencyAmount.amount50 = 50;
  //     this.currencyAmount.amount100 = 100;
  //   }
  // }

  setGiveAmount(newAmount: number) {
    if (this.currency === "SEK") {
      const sekAmount = newAmount * 10;
      this.amount = sekAmount;
    } else {
      this.amount = newAmount;
    }
  }

  currencyPrefix() {
    if (this.currency === "EUR") { return "€"; }
    if (this.currency === "USD") { return "$"; }
    return "";
  }

  setFrequency(frequency: "single" | "subscription") {
    this.frequency = frequency;
    this.amount = 0;
    this.subState = "default";
  }

  // Resets the payment process
  providerClick() {
    this.amount = 0;
    this.subState = "default";
  }

  focusOnSingleAmount() {
    this.amount = null;
    this.singleAmountField.nativeElement.focus();
  }

  focusOnMonthlyAmount() {
    this.amount = null;
    this.monthlyAmountField.nativeElement.focus();
  }

  closePaypalModal() {
    this.activeModal.dismiss('Cross click');
  }

  setSubState(newState: PaypalSubState) {
    if (newState === "default") {
      this.amount = 0;
      this.subState = newState;
    } else {
      this.subState = newState;
    }
  }

  createOrderSub() {
    console.log("1", this.subState)
    this.subState = "loading";
    const body: CreatePlanBody = { amount: this.amount, currency: this.currency }
    console.log("2", this.subState);
    this.paypalService.createPlan(body).subscribe((response) => {
      
      if (response.id) {
        this.subState = "response";
        console.log("3", this.subState);
        loadScript(
          {
          "client-id": "AQ8I2vtTtLSTugxIs-WjkMJzN0FI63WmCpWay9UAdmHMCcRCNw1njxzLI2fVwzltpkfR_xL_oYvAO7mW",
          "currency": "SEK",
          "intent": "subscription",
          // needed for subscriptions
          "vault": "true",
          "commit": true,
        })
        .then((paypal: PayPalNamespace) => {
          paypal
            .Buttons({
              createSubscription: (data, actions) => {
                return actions.subscription.create({
                  plan_id: response.id,
                  auto_renewal: true,
                });
              },
              style: {
                label: 'subscribe',
                layout: 'horizontal',
                color: 'gold',
                shape: 'rect',
                height: 40, // 20-55
                tagline: false
              },
              onApprove: (data, actions) => (
                actions.subscription.get().then((details: SubscriptionDetail) => {
                  this.subState = "subApproved";
                  const modalRef = this.modalService.open(PaypalModalComponent, { centered: true });
                  modalRef.componentInstance.subType = this.subState;
                  modalRef.componentInstance.orderDetailsSub = details;
                })
              ),
              onCancel: (data, actions) => {
                console.log('Payment was cancelled', data, actions);
                this.subState = "cancel";
                this.amount = 0;
                if (this.modalService.hasOpenModals()) {
                  this.modalService.dismissAll();
                }
                const modalRef = this.modalService.open(PaypalModalComponent, { centered: true });
                modalRef.componentInstance.subType = this.subState;
                modalRef.componentInstance.cancelMessage = "Your payment was canceled";
              },
              onError: (err) => {
                // console.log('Error:', err);
                this.subState = "error";
                // Sometimes onCancel is triggered before onError, then the cancel modal needs to be closed
                // before opening error modal.
                if (this.modalService.hasOpenModals()) {
                  this.modalService.dismissAll();
                }
                const modalRef = this.modalService.open(PaypalModalComponent, { centered: true });
                modalRef.componentInstance.subType = this.subState;
                modalRef.componentInstance.errorMessage = "An error occured";
              },
              // onClick: (data, actions) => {
              //   console.log('onClick SUB', data, actions);
              // },
            })
            .render("#paypal-button-container")
            .catch((error) => {
              console.error("failed to render the PayPal Buttons", error);
            });
        })
        .catch((error) => {
          console.error("failed to load the PayPal JS SDK script", error);
        });
      } else {
        console.log("Cound not find or create plan.");
      }
    });
  }

  createOrderSingle() {
    // console.log("1", this.subState);
    this.subState = "loading";
    // console.log("2", this.subState);

    setTimeout(() => {
      this.subState = "response";
      // console.log("3", this.subState);
      loadScript(
        {
        "client-id": "AQ8I2vtTtLSTugxIs-WjkMJzN0FI63WmCpWay9UAdmHMCcRCNw1njxzLI2fVwzltpkfR_xL_oYvAO7mW",
        "currency": "SEK",
        "intent": "capture",
        "commit": true,
      })
      .then((paypal: PayPalNamespace) => {
        paypal
          .Buttons({
            createOrder: (data, actions) => {
              // console.log("Hello", this.selectedSub);
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    currency_code: "SEK",
                    value: this.amount.toString(),
                  }
                }],
                intent: "CAPTURE",
              });
            },
            style: {
              label: 'paypal',
              layout: 'horizontal',
              color: 'gold',
              shape: 'rect',
              height: 40, // 20-55
              tagline: false
            },
            onApprove: (data, actions) => (
              actions.order.get().then((details: OrderResponseBody) => {
                // console.log('SINGLE DETAILS : ', details);
                this.subState = "singleApproved";
                const modalRef = this.modalService.open(PaypalModalComponent, { centered: true });
                modalRef.componentInstance.subType = this.subState;
                modalRef.componentInstance.orderDetailsSingle = details;
              })
            ),
            onCancel: (data, actions) => {
              // console.log('Payment was cancelled', data, actions);
              this.subState = "cancel";
              this.amount = 0;
              if (this.modalService.hasOpenModals()) {
                this.modalService.dismissAll();
              }
              const modalRef = this.modalService.open(PaypalModalComponent, { centered: true });
              modalRef.componentInstance.subType = this.subState;
              modalRef.componentInstance.cancelMessage = "Your payment was canceled";
            },
            onError: (err) => {
              // console.log('Error:', err);
              this.subState = "error";
              // Sometimes onCancel is triggered before onError, then the cancel modal needs to be closed
              // before opening error modal.
              if (this.modalService.hasOpenModals()) {
                this.modalService.dismissAll();
              }
              const modalRef = this.modalService.open(PaypalModalComponent, { centered: true });
              modalRef.componentInstance.subType = this.subState;
              modalRef.componentInstance.errorMessage = "An error occured";
            },
            // onClick: (data, actions) => {
            //   console.log('onClick SUB', data, actions);
            // },
          })
          .render("#paypal-button-container")
          .catch((error) => {
            console.error("failed to render the PayPal Buttons", error);
          });
      })
      .catch((error) => {
        console.error("failed to load the PayPal JS SDK script", error);
      });
    }, 1700);

  }
}


// private generateUUID(): string {
//   const hexValues = "0123456789ABCDEF";
//   let hexNumber = "";
//   for (let i = 0; i < 32; i++) {
//     hexNumber += hexValues[Math.floor(Math.random() * hexValues.length)];
//   }
//   console.log("HEX:", hexNumber);
//   return hexNumber;    
// }

// hasRoute(route: string) {
//   return this.router.url === route;
// }
  