import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { LyricsService } from 'src/app/services/lyrics.service';

@Component({
  selector: 'app-delete-lyrics-modal',
  templateUrl: './delete-lyrics-modal.component.html',
  styleUrls: ['./delete-lyrics-modal.component.scss']
})
export class DeleteLyricsModalComponent implements OnInit {

  selectedLyricsId: string = "";
  lyric?: Lyrics;

  constructor(
    public activeModal: NgbActiveModal,
    private lyricsService: LyricsService,
    private notifier: NotifierService,
  ) { }

  ngOnInit(): void {
    this.lyricsService.selectedLyricsId.subscribe(e => this.selectedLyricsId = e);
    this.lyricsService.getLyricsById(this.selectedLyricsId).subscribe(e => this.lyric = e);
  }

  onDelete() {
    // console.log("ID:", this.selectedLyricsId);
    this.lyricsService.deleteLyric(this.selectedLyricsId).subscribe();
    this.notifier.notify('success', "Lyric deleted.")
    this.activeModal.close();
  }
}
