import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  currentPage: string = "";

  constructor(private authService: AuthService, private router: Router) { }

  // loggedInUser: ILoggedInUser = null;

  ngOnInit(): void {
    this.currentPage = this.router.url;
    // this.authService.loggedInUser.subscribe(x => {
    //   this.loggedInUser = x;
    // });
  }

  // isExpanded = false;

  // collapse() {
  //   this.isExpanded = false;
  // }

  // toggle() {
  //   this.isExpanded = !this.isExpanded;
  // }


  // public logOut = () => {
  //   this.authService.logout();
  // }

}
