import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { comingEvents } from 'src/app/models/comingEvents';
import { HelperService } from 'src/app/services/helper.service';
import { NvGatheringModalComponent } from './modals/nv-gathering-modal/nv-gathering-modal.component';
import * as moment from 'moment';

@Component({
  selector: 'app-coming-events',
  templateUrl: './coming-events.component.html',
  styleUrls: ['./coming-events.component.scss', '../../../../styles.css'],
})
export class ComingEventsComponent implements OnInit, AfterViewInit {

  comingEventsData: ComingEvents[] = comingEvents;
  selectedEvent: ComingEvents = undefined;
  showGatheringModal: boolean = false;

  test: string;

  constructor(
    private router: Router,
    private helperService: HelperService,
    private modalService: NgbModal,
    private ref: ChangeDetectorRef,
  ) {
    helperService.selectedEvent.subscribe(e => this.selectedEvent = e);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.selectedEvent) {
      this.openEvent(this.selectedEvent);
    }
  }

  // Filters out the events that have passed
  filteredEvents() {
    let now = moment().subtract(1, "d").format("YYYYMMDD");
    return this.comingEventsData.filter((event) => moment(event.startDate).format("YYYYMMDD") > now);
  }

  showDate(date: string) {
    return moment((date), false).format("DD/MM-YYYY");
  }

  scrollToEvent() {
    this.router.navigate([], { fragment: this.selectedEvent.eventId });
  }

  lengthCheck(event: ComingEvents) {
    const eventLength = event.mainText.length;
    if (eventLength < 200) {
      return false;
    } else {
      return true;
    }
  }

  closeResult = "";

  openEvent(event: ComingEvents) {
    if (event.type === "nvg") {
      this.modalService.open(NvGatheringModalComponent, {
        size: "md",
        centered: true,
      })
        .result.then(
          (result) => {
            this.helperService.selectEvent(undefined);
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.helperService.selectEvent(undefined);
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          },
        );
    } else if (event.type === "conference") {
      // this.router.navigate([event.url]);
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.helperService.selectEvent(undefined);
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.helperService.selectEvent(undefined);
      return 'by clicking on a backdrop';
    } else {
      this.helperService.selectEvent(undefined);
      return `with: ${reason}`;
    }
  }
}
