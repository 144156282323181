import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { LyricsService } from 'src/app/services/lyrics.service';
import { LyricsModalComponent } from '../lyrics-modal.component';

@Component({
  selector: 'app-edit-lyrics-modal',
  templateUrl: './edit-lyrics-modal.component.html',
  styleUrls: ['./edit-lyrics-modal.component.scss']
})
export class EditLyricsModalComponent implements OnInit {

  selectedLyricsId: string = "";
  lyric: Lyrics;

  id: string = '';
  title: string = '';
  songWriters: string = '';
  youtubeLink: string = '';
  lyricSection: string = '';

  // @Output() editLyric = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private lyricsService: LyricsService,
    private notifier: NotifierService,
  ) { }

  ngOnInit(): void {
    this.lyricsService.selectedLyricsId.subscribe(e => this.selectedLyricsId = e);
    this.lyricsService.getLyricsById(this.selectedLyricsId).subscribe(e => this.lyric = e);
  }

  onSubmit(data: NgForm) {
    // console.log("status:", data.valid);
    // console.log("error:", data.errors);
    if (data.valid === true) {
      this.lyricsService.editStatus.next(true);
      this.lyricsService.editLyric(this.selectedLyricsId, data.value).subscribe();
      this.activeModal.close();
      this.notifier.notify('success', "Your changes has been saved.")
    }
    if (data.valid === false) {
      this.notifier.notify('error', "Could not save changes")
    }
  }


}
