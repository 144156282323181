import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute) { }

  email='';
  token = '';
  step = 1;
  showMess1 = false;
  showMess2 = false;

  password1 = '';
  password2 = '';

  ngOnInit(): void {

    this.email =this.activatedRoute.snapshot.queryParamMap.get('email');
    if (this.email) {
      this.email = decodeURI(this.email);
      this.token = this.activatedRoute.snapshot.queryParamMap.get('t');
      if (this.token) {
        this.token = decodeURI(this.token);
        this.step = 2;
      }
    }
  }
  
  reset1() {
    this.authService.resetPasswordInit(this.email)
      .subscribe(x => {
        this.showMess1 = true;
        this.email = '';
      });
  }

  reset2() {

    if (!this.password1 || this.password2 !== this.password1) {
      return;
    }

    this.authService.resetPassword(this.email,this.password1,this.token)
      .subscribe(x => {
        this.showMess2 = true;
        this.email = '';
        this.password1 = this.password2 = '';
        this.token = '';
      });
  }
}
